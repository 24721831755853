import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_anzoategui() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Anzoátegui</h2>
                            <br></br><br></br>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS Y AUTOPARTES LA MILLA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multiservicioslamilla/" target="_blank">@multiservicioslamilla</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7883.808020989002!2d-64.17219595942957!3d8.888516321361061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcd83c17f1ced6f%3A0xefbe16fd62e0253b!2sMULTISERVICIOS%20Y%20AUTOPARTES%20LA%20MILLA%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687549085061!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">EL GALPONAZO DEL HOGAR, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AVENIDA MUNICIPAL HACIENDO ESQUINA CON CALLE ZAMORA Y CALLE LA FE. <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">NEUMATICOS D'AUTOS & STORE 4X4 C.A. - S CUMANA</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AVENIDA EL EJERCITO DE BARCELONA, FRENTE AL CENTRAL MADEIRENSE. <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584248937278" target="_blank">+58 424-8937278</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </Accordion><br></br><br></br>
                        </div>
                    </center>
                </div >
            </body >
            <Footer />
        </>
    );
}

export default Comercio_anzoategui;