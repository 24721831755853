import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../App.css';
import './MainSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            <Carousel fade>
                <Carousel.Item>
                    <img src='/images/toyota1.webp' style={{ width: "100%" }} autoPlay loop />
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/images/banner-fondo-CHEGSHAN-2.webp' style={{ width: "100%" }} autoPlay loop />
                </Carousel.Item>
                <Carousel.Item>
                    <img src='/images/banner-fondo-CHEGSHAN-3.webp' style={{ width: "100%" }} autoPlay loop />
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default HeroSection;
