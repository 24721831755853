import React from "react";
import Navbar from './components/Navbar';
import './App.css';
import Inicio from './components/pages/Inicio';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
// import Productos from "./components/pages/Productos";
// import Productosv2 from "./components/pages/Productosv2";
import Productosv3 from "./components/pages/Productosv3";
import Informacion from "./components/pages/Informacion";
import Comercios from "./components/pages/Comercios";
import Contactos from "./components/pages/Contactos";
import Comercio_zulia from "./components/pages/comercios/Comercio-zulia";
import Comercio_merida from "./components/pages/comercios/Comercio-merida";
import Comercio_trujillo from "./components/pages/comercios/Comercio-trujillo";
import Comercio_tachira from "./components/pages/comercios/Comercio-tachira";
import Comercio_falcon from "./components/pages/comercios/Comercio-falcon";
import Comercio_lara from "./components/pages/comercios/Comercio-lara";
import Comercio_portuguesa from "./components/pages/comercios/Comercio-portuguesa";
import Comercio_apure from "./components/pages/comercios/Comercio-apure";
import Comercio_yaracuy from "./components/pages/comercios/Comercio-yaracuy";
import Comercio_aragua from "./components/pages/comercios/Comercio-aragua";
import Comercio_guarico from "./components/pages/comercios/Comercio-guarico";
import Comercio_miranda from "./components/pages/comercios/Comercio-miranda";
import Comercio_dc from "./components/pages/comercios/Comercio-dc";
import Comercio_bolivar from "./components/pages/comercios/Comercio-bolivar";
import Comercio_anzoategui from "./components/pages/comercios/Comercio-anzoategui";
import Comercio_sucre from "./components/pages/comercios/Comercio-sucre";
import Comercio_monagas from "./components/pages/comercios/Comercio-monagas";

function App() {
    return (
        <>
            <Router>
                <Navbar />
                <Switch>
                    <Route path='/' exact component={Inicio} />
                    <Route path='/productos' component={Productosv3} />
                    <Route path='/informacion' component={Informacion} />
                    <Route path='/comercios' component={Comercios} />
                    <Route path='/contactos' component={Contactos} />
                    <Route path='/comercio-zulia' component={Comercio_zulia} />
                    <Route path='/comercio-merida' component={Comercio_merida} />
                    <Route path='/comercio-trujillo' component={Comercio_trujillo} />
                    <Route path='/comercio-tachira' component={Comercio_tachira} />
                    <Route path='/comercio-falcon' component={Comercio_falcon} />
                    <Route path='/comercio-lara' component={Comercio_lara} />
                    <Route path='/comercio-portuguesa' component={Comercio_portuguesa} />
                    <Route path='/comercio-apure' component={Comercio_apure} />
                    <Route path='/comercio-yaracuy' component={Comercio_yaracuy} />
                    <Route path='/comercio-aragua' component={Comercio_aragua} />
                    <Route path='/comercio-guarico' component={Comercio_guarico} />
                    <Route path='/comercio-miranda' component={Comercio_miranda} />
                    <Route path='/comercio-dc' component={Comercio_dc} />
                    <Route path='/comercio-bolivar' component={Comercio_bolivar} />
                    <Route path='/comercio-anzoategui' component={Comercio_anzoategui} />
                    <Route path='/comercio-sucre' component={Comercio_sucre} />
                    <Route path='/comercio-monagas' component={Comercio_monagas} />
                </Switch>
            </Router>
        </>
    );
}

export default App;