import { m } from "framer-motion";

const data = {
    cardData: [
        {
            id: 1,
            img: "/images/CSC-102.webp",
            title: 'CSC-102',

            med: '195R14C',
            med1: '195 R14C',
            med2: '195 R 14C',
            med3: '195 R 14 C',
            med4: '195 R14 C',
            med5: '195R 14C',
            med5: '195R 14 C',

            url: '/pdf/CSC-102.pdf',
        },

        {
            id: 2,
            img: "/images/CSC-102.webp",
            title: 'CSC-102',

            med: '195R15C',
            med1: '195 R15C',
            med2: '195 R 15C',
            med3: '195 R 15 C',
            med4: '195 R15 C',
            med5: '195R 15C',
            med5: '195R 15 C',
        },

        {
            id: 3,
            img: "/images/CSC-112.webp",
            title: 'CSC-112',

            med: '7.00R15 /PR10',
            med1: '7.00R15-PR10',
            med2: '7.00R15 PR10',
            med3: '7.00 R15 PR10',
            med4: '7 / R15 PR10',
            med5: '7.00-R15 PR10',
            med5: '7.00 R15 PR10',

            url: '/pdf/CSC-112.pdf',
        },

        {
            id: 4,
            img: "/images/CSC-302.webp",
            title: 'CSC-302',

            med: '215/70R15',
            med1: '215-70R15',
            med2: '215 70R15',
            med3: '215 70R 15',
            med4: '215 / 70R 15',
            med5: '215-70 R 15',
            med5: '215 70 R 15',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Ford',
            mark3: 'Jeep',
            mark4: 'Mazda',
            mark5: 'Mitsubishi',
            mark6: 'Nissan',
            mark7: 'Toyota',
            mark8: 'Chrysler Dodge',
            mark9: 'Kia',

            model: 'Malibu',
            model1: 'Blazer',
            model2: 'Caprice',
            model3: 'Monte Carlo',
            model4: 'Dakota 2002',
            model5: 'F-150',
            model6: 'Ranger',
            model7: 'Bronco',
            model8: 'Cherokee',
            model9: 'Grand Cherokee',
            model10: 'Wrangler',
            model11: 'Comanche',
            model12: 'B-Series',
            model13: 'Triton',
            model14: 'Frontier',
            model15: 'Pathfinder',
            model16: 'Pickup',
            model17: 'T-100',
            model18: 'Caravan',
            model19: 'Carnival',
            model20: 'Sedona',

            url: '/pdf/CSC-302.pdf',
        },

        {
            id: 5,
            img: "/images/CSC-302.webp",
            title: 'CSC-302',

            med: '235/75R15',
            med1: '235-75R15',
            med2: '235 75R15',
            med3: '235 75R 15',
            med4: '235 / 75R 15',
            med5: '235-75 R 15',
            med5: '235 75 R 15',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Ford',
            mark3: 'Jeep',
            mark4: 'Mazda',
            mark5: 'Mitsubishi',
            mark6: 'Nissan',
            mark7: 'Toyota',

            model: 'Blazer LS',
            model1: 'Silverado',
            model2: 'LUV',
            model3: 'Suburban 1500',
            model4: 'Tahoe',
            model5: 'C1500',
            model6: 'Dakota',
            model7: 'Durango',
            model8: 'Ram 1500 Van',
            model9: 'Bronco 1996',
            model10: 'Bronco 1997',
            model11: 'Explorer Sport',
            model12: 'F-100',
            model13: 'F-150',
            model14: 'Ranger',
            model15: 'Wrangler',
            model16: 'B-Series',
            model17: 'L200',
            model18: 'Pajero',
            model19: 'Montero Dakar',
            model20: 'Frontier',
            model21: 'Pathfinder',
            model22: 'Terrano',
            model23: 'Hilux',
            model24: 'Land Cruiser',
            model25: 'T-100',

            url: '/pdf/CSC-302.pdf',

        },

        {
            id: 6,
            img: "/images/CSC-302.webp",
            title: 'CSC-302',

            med: '245/75R16',
            med1: '245-75R16',
            med2: '245 75R16',
            med3: '245 75R 16',
            med4: '245 / 75R 16',
            med5: '245-75 R 16',
            med5: '245 75 R 16',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Ford',
            mark3: 'Jeep',
            mark4: 'Mazda',
            mark5: 'Nissan',
            mark6: 'Toyota',

            model: 'Avalanche',
            model1: 'Silverado 1500',
            model2: 'Silverado 2500',
            model3: 'Silverado 3500',
            model4: 'Tahoe',
            model5: 'Sonora',
            model6: 'K1500',
            model7: 'K2500',
            model8: 'K3500',
            model9: 'Ram 1500',
            model10: 'Ram 2500',
            model11: 'Ram 3500',
            model12: 'F-150',
            model13: 'Ranger',
            model14: 'Wrangler',
            model15: 'B-Series',
            model16: 'Pathfinder',
            model17: 'Tacoma',

            url: '/pdf/CSC-302.pdf',
        },

        {
            id: 7,
            img: "/images/CSC-302.webp",
            title: 'CSC-302',

            med: '265/60R18',
            med1: '265-60R18',
            med2: '265 60R18',
            med3: '265 60R 18',
            med4: '265 / 60R 18',
            med5: '265-60 R 18',
            med5: '265 60 R 18',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Ford',
            mark3: 'Jeep',
            mark4: 'Mazda',
            mark5: 'Mitsubishi',
            mark6: 'Nissan',
            mark7: 'RAM',
            mark8: 'Toyota',

            model: 'TrailBlazer',
            model1: 'Dakota',
            model2: 'Durango',
            model3: 'Ram 1500',
            model4: 'Ram 2500',
            model5: 'Ram 3500',
            model6: 'F-150',
            model7: 'Lobo',
            model8: 'Ranger',
            model9: 'Grand Cherokee',
            model10: 'BT-50',
            model11: 'Challenger',
            model12: 'L200',
            model13: 'Shogun',
            model14: 'Pajero',
            model15: 'Triton',
            model16: 'Nativa',
            model17: 'Frontier',
            model18: 'Pathfinder',
            model19: 'Dakota',
            model20: '4Runner',
            model21: 'Fortuner',
            model22: 'Hilux',
            model23: 'Land Cruiser',
            model24: 'Tacoma',
            model25: 'SW4',

            url: '/pdf/CSC-302.pdf',
        },

        {
            id: 8,
            img: "/images/CSC-302.webp",
            title: 'CSC-302',

            med: '265/65R17',
            med1: '265-65R17',
            med2: '265 65R17',
            med3: '265 65R 17',
            med4: '265 / 65R 17',
            med5: '265-65 R 17',
            med5: '265 65 R 17',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Ford',
            mark3: 'Hummer',
            mark4: 'Mazda',
            mark5: 'Mitsubishi',
            mark6: 'Nissan',
            mark7: 'RAM',
            mark8: 'Toyota',

            model: 'Colorado',
            model1: 'D-Max',
            model2: 'Dakota',
            model3: 'Durango',
            model4: 'Ram 1500',
            model5: 'Ram 2500',
            model6: 'Ranger',
            model7: 'H3',
            model8: 'BT-50',
            model9: 'Challenger',
            model10: 'L200',
            model11: 'Montero',
            model12: 'Pajero',
            model13: 'Shogun',
            model14: 'Triton',
            model15: 'Nativa',
            model16: 'Frontier',
            model17: 'Pathfinder',
            model18: 'Dakota',
            model19: '4Runner',
            model20: 'Fortuner',
            model21: 'Hilux',
            model22: 'Land Cruiser',
            model23: 'SW4',
            model24: 'Tacoma',
            model25: 'Tundra',

            url: '/pdf/CSC-302.pdf',
        },

        {
            id: 9,
            img: "/images/CSC-303.webp",
            title: 'CSC-303',

            med: '235/65R17',
            med1: '235-65R17',
            med2: '235 65R17',
            med3: '235 65R 17',
            med4: '235 / 65R 17',
            med5: '235-65 R 17',
            med5: '235 65 R 17',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Ford',
            mark3: 'Hyundai',
            mark4: 'Jeep',
            mark5: 'Kia',
            mark6: 'Mitsubishi',
            mark7: 'Toyota',

            model: 'Captiva',
            model1: 'Dakota',
            model2: 'Durango',
            model3: 'Bronco Sport',
            model4: 'Santa Fe',
            model5: 'Tucson',
            model6: 'Santa Fe Sport',
            model7: 'Cherokee',
            model8: 'Grand Cherokee',
            model9: 'Liberty',
            model10: 'Comanche',
            model11: 'Sorento',
            model12: '4Runner',

            url: '/pdf/CSC-303.pdf',

        },

        {
            id: 10,
            img: "/images/CSC-303.webp",
            title: 'CSC-303',

            med: '265/60R18',
            med1: '265-60R18',
            med2: '265 60R18',
            med3: '265 60R 18',
            med4: '265 / 60R 18',
            med5: '265-60 R 18',
            med5: '265 60 R 18',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Ford',
            mark3: 'Jeep',
            mark4: 'Mitsubishi',
            mark5: 'Nissan',
            mark6: 'Toyota',


            model: 'Trailblazer',
            model1: 'Colorado',
            model2: 'Dakota',
            model3: 'Durango',
            model4: 'Ram 1500',
            model5: 'Ram 2500',
            model6: 'F-150',
            model7: 'Ranger',
            model8: 'Lobo',
            model9: 'Grand Cherokee',
            model10: 'Challenger',
            model11: 'Montero',
            model12: 'Shogun',
            model13: 'Strada',
            model14: 'L200',
            model15: 'Nativa',
            model16: 'Pajero',
            model17: 'Frontier',
            model18: 'Pathfinder',
            model19: '4Runner',
            model20: 'Tacoma',
            model21: 'Fortuner',
            model22: 'Hilux',
            model23: 'Land Cruiser',
            model24: 'SW4',
            model25: 'Triton',

            url: '/pdf/CSC-303.pdf',
        },

        {
            id: 11,
            img: "/images/CSC-701.webp",
            title: 'CSC-701',

            med: '215/45ZR17',
            med1: '215-45ZR17',
            med2: '215 45ZR17',
            med3: '215 45ZR 17',
            med4: '215 / 45ZR 17',
            med5: '215-45 ZR 17',
            med5: '215 45 ZR 17',

            mark: 'Toyota',
            mark1: 'Chrysler',
            mark2: 'Fiat',
            mark3: 'Ford',
            mark4: 'Honda',
            mark5: 'Hyundai',
            mark6: 'Mazda',
            mark7: 'Mitsubishi',
            mark8: 'Nissan',
            mark9: 'Peugeot',
            mark10: 'Renault',
            mark11: 'Subaru',
            mark12: 'Volkswagen',

            model: 'Corolla 1.8 2016',
            model: 'Corolla 1.8 2017',
            model: 'Corolla 1.8 2018',
            model: 'Corolla 1.8 2019',
            model: 'Corolla 1.8 2020',
            model: 'Corolla 1.8 2021',
            model1: 'Camry',
            model2: 'Celica',
            model3: 'Levin',
            model4: 'Prius',
            model5: 'Vienta',
            model6: 'Cirrus',
            model7: 'Siena',
            model8: 'Linea',
            model9: 'Focus',
            model10: 'EcoSport',
            model11: 'Accord',
            model12: 'Civic',
            model13: 'S2000',
            model14: 'Accent',
            model15: 'Elantra',
            model16: 'Lantra',
            model17: 'Mazda 6',
            model18: 'Protege',
            model19: 'Roadster',
            model20: 'Aspire',
            model21: 'Eclipse',
            model22: 'Eterna',
            model23: 'Galant',
            model24: 'Lancer Evolution',
            model25: 'Tilda',
            model26: 'Sentra',
            model27: '207',
            model28: 'Clio',
            model29: 'Impreza',
            model30: 'Polo',

            url: '/pdf/CSC-701.pdf',
        },

        {
            id: 12,
            img: "/images/CSC-701.webp",
            title: 'CSC-701',

            med: '225/45ZR18',
            med1: '225-45ZR18',
            med2: '225 45ZR18',
            med3: '225 45ZR 18',
            med4: '225 / 45ZR 18',
            med5: '225-45 ZR 18',
            med5: '225 45 ZR 18',

            mark: 'BMW',
            mark1: 'Chevrolet',
            mark2: 'Chrysler',
            mark3: 'Dodge',
            mark4: 'Ford',
            mark5: 'Honda',
            mark6: 'Hyundai',
            mark7: 'Mazda',
            mark8: 'Mitsubishi',
            mark9: 'Nissan',
            mark10: 'Renault',

            model: 'M3',
            model1: 'Cruze',
            model2: 'Sebring',
            model3: 'Stratus',
            model4: 'Fusion',
            model5: 'Accord',
            model6: 'sonata',
            model7: 'Mazda 3',
            model8: 'Outlander',
            model9: '350Z',
            model10: 'Altima',
            model11: 'Scenic',

            url: '/pdf/CSC-701.pdf',
        },

        {
            id: 13,
            img: "/images/CSC-701.webp",
            title: 'XL CSC-701',

            med: '225/50ZR17',
            med1: '225-50ZR17',
            med2: '225 50ZR17',
            med3: '225 50ZR 17',
            med4: '225 / 50ZR 17',
            med5: '225-50 ZR 17',
            med5: '225 50 ZR 17',

            mark: 'Chevrolet',
            mark1: 'Chrysler',
            mark2: 'Dodge',
            mark3: 'Ford',
            mark4: 'Honda',
            mark5: 'Hyundai',
            mark6: 'Mazda',
            mark7: 'Mitsubishi',
            mark8: 'Nissan',
            mark9: 'Renault',
            mark10: 'Toyota',


            model: 'Cruze',
            model1: 'Orlando',
            model2: 'Sebring',
            model3: 'Stratus',
            model4: 'Fusion',
            model5: 'Mustang',
            model6: 'Focus Electric',
            model7: 'Accord',
            model8: 'sonata',
            model9: 'Mazda 6',
            model10: 'Eclipse',
            model11: 'Outlander',
            model12: 'GTO',
            model13: '350Z',
            model14: 'Megane',
            model15: 'Scenic',
            model16: 'Supra',

            url: '/pdf/CSC-701.pdf',
        },
        {
            id: 14,
            img: "/images/CSC-801.webp",
            title: 'CSC-801',

            med: '165/70R13',
            med1: '165-70R13',
            med2: '165 70R13',
            med3: '165 70R 13',
            med4: '165 / 70R 13',
            med5: '165-70 R 13',
            med5: '165 70 R 13',

            mark: 'Ford',
            mark1: 'Mazda',
            mark2: 'Chrysler Dodge',
            mark3: 'Hyundai',
            mark4: 'Renault',
            mark5: 'Fiat',
            mark6: 'Toyota',
            mark7: 'Venirauto',
            mark8: 'Chevrolet',
            mark9: 'Honda',

            model: 'Festiva',
            model1: '323 1.3',
            model2: 'Brisa',
            model3: 'Accent 1.3',
            model4: 'Accent 1.5',
            model5: 'Twingo',
            model6: 'Fiorino',
            model7: 'Palio 1.6 Luxury',
            model8: 'Palio 1.6 Fire',
            model9: 'Uno 1.3 Sempre',
            model10: 'Starlet 1.3 L',
            model11: 'Fiesta 1.6',
            model12: 'Clio 1.4',
            model13: 'Civic',
            model14: 'Corolla',
            model15: 'Corsa',
            model16: 'Chevy',
            model17: 'Tipo',

            url: '/pdf/CSC-801.pdf',
        },
        {
            id: 15,
            img: "/images/CSC-801.webp",
            title: 'CSC-801',

            med: '175/65R14',
            med1: '175-65R14',
            med2: '175 65R14',
            med3: '175 65R 14',
            med4: '175 / 65R 14',
            med5: '175-65 R 14',
            med5: '175 65 R 14',

            mark: 'Ford',
            mark1: 'Hyundai',
            mark2: 'Venirauto',
            mark3: 'Renault',
            mark4: 'Fiat',
            mark5: 'Chevrolet',
            mark6: 'Dodge',
            mark7: 'Honda',
            mark8: 'Mazda',
            mark9: 'Mitsubishi',
            mark10: 'Nissan',
            mark11: 'Peugeot',
            mark12: 'Toyota',
            mark13: 'Volkswagen',

            model: 'Fiesta 1.6',
            model1: 'Elantra 1.6 1996',
            model2: 'Elantra 1.6 1997',
            model3: 'Elantra 1.6 1998',
            model4: 'Elantra 1.6 1999',
            model5: 'Getz',
            model6: 'Arauca',
            model7: 'Clio 1.4',
            model8: 'Symbol 1.4',
            model9: 'Tipo',
            model10: 'Aveo',
            model11: 'Corsa',
            model12: 'Spark',
            model13: 'Forza',
            model14: 'Neon',
            model15: 'Siena',
            model16: 'Fiorino',
            model17: 'Palio',
            model18: 'Uno',
            model19: 'Civic',
            model20: 'Accent',
            model21: '323',
            model22: '121',
            model23: 'Lancer',
            model24: 'Sentra',
            model25: '207',
            model26: '206',
            model27: 'Twingo',
            model28: 'Corolla',
            model29: 'Belta',
            model30: 'Vitz',
            model31: 'Yaris',
            model32: 'Fox',
            model33: 'Golf',
            model34: 'Gol',
            model35: 'Polo',

            url: '/pdf/CSC-801.pdf',
        },


        {
            id: 16,
            img: "/images/CSC-801.webp",
            title: 'CSC-801',

            med: '185/70R13',
            med1: '185-70R13',
            med2: '185 70R13',
            med3: '185 70R 13',
            med4: '185 / 70R 13',
            med5: '185-70 R 13',
            med5: '185 70 R 13',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Ford',
            mark4: 'Honda',
            mark5: 'Mazda',
            mark6: 'Mitsubishi',

            model: 'Monza',
            model1: 'Le Mans',
            model2: 'Prince',
            model3: 'Racer',
            model4: 'Neon',
            model5: 'Mustang',
            model6: 'Sierra',
            model7: 'Accord',
            model8: 'Civic',
            model9: 'Camry',
            model10: 'Corolla',
            model11: 'Celica',
            model12: '626',
            model13: 'RX-7',
            model14: 'Chariot',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 17,
            img: '/images/CSC-801.webp',
            title: 'CSC-801',

            med: '165/65R13',
            med1: '165-65R13',
            med2: '165 65R13',
            med3: '165 65R 13',
            med4: '165 / 65R 13',
            med5: '165-65 R 13',
            med5: '165 65 R 13',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Fiat',
            mark3: 'Ford',
            mark4: 'Honda',
            mark5: 'Kia',
            mark6: 'Peugeot',
            mark7: 'Renault',
            mark8: 'Subaru',
            mark9: 'Volkswagen',

            model: 'spark',
            model1: 'matiz',
            model2: 'Punto',
            model3: 'Fiesta',
            model4: 'Ka',
            model5: 'Ikon',
            model6: 'City',
            model7: 'Civic',
            model8: 'Pride',
            model9: '106',
            model20: 'Lutecia',
            model21: 'Justy',
            model22: 'Polo',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 18,
            img: '/images/CSC-801.webp',
            title: 'CSC-801',

            med: '175/70R13',
            med1: '175-70R13',
            med2: '175 70R13',
            med3: '175 70R 13',
            med4: '175 / 70R 13',
            med5: '175-70 R 13',
            med5: '175 70 R 13',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Fiat',
            mark3: 'Honda',
            mark4: 'Hyundai',
            mark5: 'Kia',
            mark6: 'Mazda',
            mark7: 'Mitsubishi',
            mark8: 'Nissan',
            mark9: 'Opel',
            mark10: 'Peugeot',
            mark11: 'Renault',
            mark12: 'Toyota',
            mark13: 'Volkswagen',

            model: 'Aveo',
            model1: 'Chevy',
            model2: 'Colt',
            model3: 'Punto',
            model4: 'Panda',
            model5: 'Civic',
            model6: 'CR-X',
            model7: 'Concerto',
            model8: 'Accent',
            model9: 'Excel',
            model10: 'Lantra',
            model11: 'Rio',
            model12: '323',
            maodel13: 'Allegro',
            model14: 'Lancer',
            model15: '200SX',
            model16: 'Sentra',
            model17: 'Astra',
            model18: 'Corsa',
            model19: '206',
            model20: 'Clio',
            model21: 'Symbol',
            model22: 'Corolla',
            model23: 'Golf',
            model24: 'Polo',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 19,
            img: '/images/CSC-801.webp',
            title: "CSC-801",

            med: '185/60R14',
            med1: '185-60R14',
            med2: '185 60R14',
            med3: '185 60R 14',
            med4: '185 / 60R 14',
            med5: '185-60 R 14',
            med5: '185 60 R 14',

            mark: 'Chevrolet',
            mark1: 'Dodge',
            mark2: 'Fiat',
            mark3: 'Ford',
            mark4: 'Honda',
            mark5: 'Hyundai',
            mark6: 'Mazda',
            mark7: 'Mitsubishi',
            mark8: 'Nissan',
            mark9: 'Opel',
            mark9: 'Peugeot',
            mark10: 'Renault',
            mark11: 'Toyota',
            mark12: 'Volkswagen',

            model: 'Aveo',
            model1: 'Chevy',
            model2: 'Corsa',
            model3: 'Spark',
            model4: 'Forza',
            model5: 'Colt',
            model6: 'Palio',
            model7: 'Punto',
            model8: 'Siena',
            model9: 'Tempra',
            model10: 'Fiesta',
            model11: 'Ka',
            model12: 'Ikon',
            model13: 'Civic',
            model14: 'CR-X',
            model15: 'Accent',
            model16: 'Elantra',
            model17: 'Excel',
            model18: '323',
            model19: 'Mazda2',
            model20: 'Lancer',
            model21: 'Sentra',
            model22: 'Astra',
            model23: 'Corsa',
            model24: '306',
            model25: 'Clio',
            model26: 'Symbol',
            model27: 'Kangoo',
            model28: 'Corolla',
            model29: 'Fox',
            model30: 'Golf',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 20,
            img: '/images/CSC-801.webp',
            title: "CSC-801",

            med: '185/65R14',
            med1: '185-65R14',
            med2: '185 65R14',
            med3: '185 65R 14',
            med4: '185 / 65R 14',
            med5: '185-65 R 14',
            med5: '185 65 R 14',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Fiat',
            mark4: 'Ford',
            mark5: 'Honda',
            mark6: 'Hyundai',
            mark7: 'Kia',
            mark8: 'Mazda',
            mark9: 'Mitsubishi',
            mark10: 'Nissan',
            mark11: 'Opel',
            mark12: 'Peugeot',
            mark13: 'Renault',
            mark14: 'Subaru',
            mark15: 'Toyota',
            mark16: 'Volkswagen',

            model: 'Astra',
            model1: 'Chevy',
            model2: 'Optra',
            model3: 'Le Mans',
            model4: 'Prince',
            model5: 'Racer',
            model6: 'Neon',
            model7: 'Forza',
            model8: 'Idea',
            model9: 'Palio',
            model10: 'Tipo',
            model11: 'Sierra',
            model12: 'Focus',
            model13: 'Accord',
            model14: 'CR-X',
            model15: 'Civic',
            model16: 'Prelude',
            model17: 'Integra',
            model18: 'Accent',
            model19: 'Rio',
            model20: '323',
            model21: 'Allegro',
            model22: 'Lancer',
            model23: 'Sentra',
            model24: 'Astra',
            model25: '206',
            model26: '306',
            model27: '207',
            model28: '405',
            model29: 'Kangoo',
            model30: 'Impreza',
            model31: 'Corolla',
            model32: 'Celica',
            model33: 'Gol',
            model34: 'Polo',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 21,
            img: '/images/CSC-801.webp',
            title: "CSC-801",

            med: '185/70R14',
            med1: '185-70R14',
            med2: '185 70R14',
            med3: '185 70R 14',
            med4: '185 / 70R 14',
            med5: '185-70 R 14',
            med5: '185 70 R 14',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Ford',
            mark4: 'Honda',
            mark5: 'Hyundai',
            mark6: 'Mazda',
            mark7: 'Mitsubishi',
            mark8: 'Nissan',
            mark9: 'Opel',
            mark10: 'renault',
            mark11: 'Subaru',
            mark12: 'Toyota',
            mark13: 'Volkswagen',

            model: 'Celebrity',
            model1: 'Classic',
            model2: 'Rezzo',
            model3: 'Rezzo',
            model4: 'Charger',
            model5: 'Spirit',
            model6: 'Fusion',
            model7: 'Accord',
            model8: 'Civic',
            model9: 'Prelude',
            model10: 'Sonata',
            model11: '626',
            model12: 'Emeraude',
            model13: 'Eterna',
            model14: 'Aspire',
            model15: 'Eclipse',
            model16: 'Silvia',
            model17: 'Astra',
            model18: 'Corsa',
            model19: 'Logan',
            model20: 'Kangoo',
            model21: 'Scala',
            model22: 'Impreza',
            model23: 'Camry',
            model24: 'Celica',
            model25: 'Corolla',
            model26: 'Polo',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 22,
            img: '/images/CSC-801.webp',
            title: "CSC-801",

            med: '195/70R14',
            med1: '195-70R14',
            med2: '195 70R14',
            med3: '195 70R 14',
            med4: '195 / 70R 14',
            med5: '195-70 R 14',
            med5: '195 70 R 14',

            mark: 'Chevrolet',
            mark1: 'Chrysler',
            mark2: 'Dodge',
            mark3: 'Ford',
            mark4: 'Honda',
            mark5: 'Hyundai',
            mark6: 'Mitsubishi',
            mark7: 'Toyota',
            mark8: 'Volkswagen',

            model: 'Camaro',
            model1: 'Cavalier',
            model2: 'Celebrity',
            model3: 'Saratoga',
            model4: 'Sebring',
            model5: 'Challenger',
            model6: 'Avenger',
            model7: 'Stratus',
            model8: 'Ranger',
            model9: 'Accord',
            model10: 'Sonata',
            model11: 'Sigma',
            model12: 'Camry',
            model13: 'Cavalier',
            model14: 'Golf',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 23,
            img: '/images/CSC-801.webp',
            title: "CSC-801",

            med: '195/50R15',
            med1: '195-50R15',
            med2: '195 50R15',
            med3: '195 50R 15',
            med4: '195 / 50R 15',
            med5: '195-50 R 15',
            med5: '195 50 R 15',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Fiat',
            mark4: 'Ford',
            mark5: 'Hyundai',
            mark6: 'Kia',
            mark7: 'Mazda',
            mark8: 'Mitsubishi',
            mark9: 'Opel',
            mark10: 'Peugeot',
            mark11: 'Renault',
            mark12: 'Volkswagen',

            model: 'Spark',
            model1: 'Lanos',
            model2: 'Forza',
            model3: 'Palio',
            model4: 'Punto',
            model5: 'Siena',
            model6: 'Tipo',
            model7: 'Fiesta',
            model8: 'Ka',
            model9: 'Ikon',
            model10: 'Elantra',
            model11: 'Accent',
            model12: 'Lantra',
            model13: 'Sephia',
            model14: '323',
            model15: 'Mazda2',
            model16: 'Protege',
            model17: 'Lancer',
            model18: 'Astra',
            model19: 'Corsa',
            model20: '106',
            model21: '107',
            model22: 'Clio',
            model23: 'Symbol',
            model24: 'Golf',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 24,
            img: '/images/CSC-801.webp',
            title: 'CSC-801',

            med: '195/55R15',
            med1: '195-55R15',
            med2: '195 55R15',
            med3: '195 55R 15',
            med4: '195 / 55R 15',
            med5: '195-55 R 15',
            med5: '195 55 R 15',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Fiat',
            mark4: 'Ford',
            mark5: 'Honda',
            mark5: 'Hyundai',
            mark6: 'Kia',
            mark7: 'Mazda',
            mark8: 'Mitsubishi',
            mark9: 'Nissan',
            mark10: 'Opel',
            mark11: 'Peugeot',
            mark12: 'Renault',
            mark13: 'Toyota',
            mark14: 'Volkswagen',

            model: 'Spark EV',
            model1: 'Optra',
            model2: 'Le Mans',
            model3: 'Prince',
            model4: 'Racer',
            model5: 'Neon',
            model6: 'Idea',
            model7: 'Palio',
            model8: 'Tipo',
            model9: 'Siena',
            model10: 'Laser',
            model11: 'Fiesta',
            model12: 'Ka',
            model13: 'Focus',
            model14: 'Accord',
            model15: 'Civic',
            model16: 'Integra',
            model17: 'Prelude',
            model18: 'CR-X',
            model18: 'Accent',
            model19: 'Pride',
            model20: 'Rio',
            model21: '323',
            model22: 'Allegro',
            model23: 'Protege',
            model24: 'Lancer',
            model25: 'Lancer Evolution',
            model26: 'Sentra',
            model27: 'Astra',
            model28: '206',
            model29: '207',
            model30: '306',
            model31: 'Clio',
            model32: 'Kangoo',
            model33: 'Symbol',
            model34: 'Megane',
            model35: 'Corolla',
            model36: 'Polo',
            model37: 'SpaceFox',
            model38: 'Fox',
            model39: 'Gol',
            model40: 'CrossFox',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 25,
            img: '/images/CSC-801.webp',
            title: 'CSC-801',

            med: '195/60R15',
            med1: '195-60R15',
            med2: '195 60R15',
            med3: '195 60R 15',
            med4: '195 / 60R 15',
            med5: '195-60 R 15',
            med5: '195 60 R 15',

            mark: 'Chevrolet',
            mark1: 'Chrysler',
            mark2: 'Daewoo',
            mark3: 'Dodge',
            mark4: 'Fiat',
            mark5: 'Ford',
            mark6: 'Honda',
            mark7: 'Hyundai',
            mark8: 'Kia',
            mark9: 'Mazda',
            mark10: 'Mitsubishi',
            mark11: 'Nissan',
            mark12: 'Opel',
            mark13: 'Peugeot',
            mark14: 'Subaru',
            mark15: 'Toyota',

            model: 'Astra',
            model1: 'Optra',
            model2: 'Cirrus',
            model3: 'Tacuma',
            model4: 'Charger',
            model5: 'Idea',
            model6: 'Punto',
            model7: 'Fiesta',
            model8: 'Focus',
            model9: 'Fusion',
            model10: 'Accord',
            model11: 'Civic',
            model12: 'Prelude',
            model13: 'Elantra',
            model14: 'Sonata',
            model15: 'Rio',
            model16: '323',
            model17: '626',
            model18: 'Aspire',
            model19: 'Emeraude',
            model20: 'Eterna',
            model21: 'Eclipse',
            model22: 'Lancer',
            model23: 'Silvia',
            model24: 'Sentra',
            model25: 'astra',
            model26: 'Corsa',
            model27: '207',
            model28: 'Impreza',
            model29: 'Camry',
            model30: 'Celica',
            model31: 'Corolla',
            model32: 'Prius',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 26,
            img: "/images/CSC-801.webp",
            title: 'CSC-801',

            med: '195/65R15',
            med1: '195-65R15',
            med2: '195 65R15',
            med3: '195 65R 15',
            med4: '195 / 65R 15',
            med5: '195-65 R 15',
            med5: '195 65 R 15',

            mark: 'BMW',
            mark1: 'Chevrolet',
            mark2: 'Chrysler',
            mark3: 'Dodge',
            mark4: 'Fiat',
            mark5: 'Ford',
            mark6: 'Honda',
            mark7: 'Hyundai',
            mark8: 'Mazda',
            mark9: 'Mitsubishi',
            mark10: 'Nissan',
            mark11: 'Opel',
            mark12: 'Peugeot',
            mark13: 'RAM',
            mark14: 'Renault',
            mark15: 'Porsche',

            model: '5 Series',
            model1: 'Aveo',
            model2: 'Cavalier',
            model3: 'Cruze',
            model4: 'Monza',
            model5: 'Sebring',
            model6: 'Avenger',
            model7: 'Neon',
            model8: 'Challenger',
            model9: 'Tipo',
            model10: 'palio',
            model11: 'Strada',
            model12: 'Focus',
            model13: 'Accord',
            model14: 'Civic',
            model15: 'Prelude',
            model16: 'Integra',
            model17: 'Elantra',
            model18: 'Sonata',
            model19: 'Mazda3',
            model20: 'Mazda5',
            model21: 'Mazda6',
            model22: 'Sigma',
            model23: 'Eclipse',
            model24: 'Sentra',
            model25: 'Silvia',
            model26: 'Tiida',
            model27: 'Astra',
            model28: '207',
            model29: '307',
            model30: '308',
            model31: '408',
            model32: 'V1000',
            model33: 'Kangoo',
            model34: 'Scenic',
            model35: 'Megane',
            model36: '911',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 27,
            img: '/images/CSC-801.webp',
            title: 'CSC-801',

            med: '205/70R15',
            med1: '205-70R15',
            med2: '205 70R15',
            med3: '205 70R 15',
            med4: '205 / 70R 15',
            med5: '205-70 R 15',
            med5: '205 70 R 15',

            mark: 'Chevrolet',
            mark1: 'Buick',
            mark2: 'Chrysler',
            mark3: 'Dodge',
            mark4: 'Fiat',
            mark5: 'Ford',
            mark6: 'Honda',
            mark7: 'Hyundai',
            mark8: 'Kia',
            mark9: 'Mazda',
            mark10: 'Mitsubishi',
            mark11: 'Opel',
            mark123: 'Toyota',

            model: 'Impala',
            model1: 'Monte Carlo',
            model2: 'Lumina',
            model3: 'Lumina APV',
            model4: 'Century',
            model5: 'Voyager',
            model6: 'Grand Voyager',
            model7: 'Caliber',
            model8: 'Caravan',
            model9: 'Strada',
            model10: 'Idea',
            model11: 'Windstar',
            model12: 'CR-V',
            model13: 'Sonata',
            model14: 'Sportage',
            model15: 'B-Series',
            model16: 'L300',
            model17: 'Pajero',
            model18: 'Movano',
            model19: 'Cami',
            model20: 'Hilux',
            model21: 'Sienna',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 28,
            img: "/images/CSC-801.webp",
            title: 'CSC-801',

            med: '205/55R16',
            med1: '205-55R16',
            med2: '205 55R16',
            med3: '205 55R 16',
            med4: '205 / 55R 16',
            med5: '205-55 R 16',
            med5: '205 55 R 16',

            mark: 'Audi',
            mark1: 'Chevrolet',
            mark2: 'Chrysler',
            mark3: 'Daewoo',
            mark4: 'Dodge',
            mark5: 'Fiat',
            mark6: 'Ford',
            mark7: 'Honda',
            mark8: 'Hyundai',
            mark9: 'Mazda',
            mark10: 'Mitsubishi',

            model: 'A3',
            model1: 'A4',
            model2: 'Astra',
            model3: 'Aveo',
            model4: 'Cavalier',
            model5: 'Cruze',
            model6: 'Monza',
            model7: 'Optra',
            model8: 'Zafira',
            model9: 'Cirrus',
            model10: 'Sebring',
            model11: 'PT Cruiser',
            model12: 'Tacuma',
            model13: 'Leganza',
            model14: 'Avenger',
            model15: 'Challenger',
            model16: 'Neon',
            model17: 'Stratus',
            model18: 'Fiorino',
            model19: 'Strada',
            model20: 'Tipo',
            model21: 'Focus',
            model22: 'Accord',
            model23: 'Civic',
            model24: 'Elantra',
            model25: 'Sonata',
            model26: '626',
            model27: 'Mazda3',
            model28: 'Mazda5',
            model29: 'Mazda6',
            model30: 'Aspire',
            model31: 'Eclipse',
            model32: 'Emeraude',
            model33: 'Eterna',
            model34: 'Galant',
            model35: 'Space Wagon',

            url: '/pdf/CSC-801.pdf',
        },

        {
            id: 29,
            img: "/images/CSC-802.webp",
            title: 'CSC-802',

            med: '185/65R14',
            med1: '185-65R14',
            med2: '185 65R14',
            med3: '185 65R 14',
            med4: '185 / 65R 14',
            med5: '185-65 R 14',
            med5: '185 65 R 14',
            med6: '195 55 R 15',
            med7: '195/5515',
            med8: '195-5515',
            med9: '195 5515',
            med10: '195 55 15',
            med11: '195 / 55 15',
            med12: '195-55  15',
            med13: '195 55  15',
            med14: '195/55 15',
            med15: '195-55 15',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Fiat',
            mark4: 'Ford',
            mark5: 'Honda',
            mark6: 'Hyundai',
            mark7: 'Kia',
            mark8: 'Mazda',
            mark9: 'Mitsubishi',
            mark10: 'Opel',
            mark11: 'Renault',
            mark12: 'Subaru',
            mark13: 'Toyota',
            mark14: 'Volkswagen',

            model: 'Astra',
            model1: 'Chevy',
            model2: 'Optra',
            model3: 'Racer',
            model4: 'LeMans',
            model5: 'Prince',
            model6: 'Forza',
            model7: 'Neon',
            model8: 'Palio',
            model9: 'Tipo',
            model10: 'Idea',
            model11: 'Focus',
            model12: 'Accord',
            model13: 'Civic',
            model14: 'CR-X',
            model15: 'Accent',
            model16: 'Elantra LaVita',
            model17: 'Rio',
            model18: '323',
            model19: 'Allegro',
            model20: 'Protege',
            model21: 'Lancer',
            model22: 'Astra',
            model23: 'Kangoo',
            model24: 'Impreza',
            model25: 'Corolla',
            model26: 'Celica',
            model27: 'Gol',
            model28: 'Polo',

            url: '/pdf/CSC-802.pdf',
        },

        {
            id: 30,
            img: '/images/CSC-802.webp',
            title: 'CSC-802',

            med: '195/50R15',
            med1: '195-50R15',
            med2: '195 50R15',
            med3: '195 50R 15',
            med4: '195 / 50R 15',
            med5: '195-50 R 15',
            med5: '195 50 R 15',
            med6: '195 50 R 15',
            med7: '195/5015',
            med8: '195-5015',
            med9: '195 5015',
            med10: '195 50 15',
            med11: '195 / 50 15',
            med12: '195-50  15',
            med13: '195 50  15',
            med14: '195/50 15',
            med15: '195-50 15',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Fiat',
            mark4: 'Ford',
            mark5: 'Hyundai',
            mark6: 'Mazda',
            mark7: 'Mitsubishi',
            mark8: 'Opel',
            mark9: 'Peugeot',
            mark10: 'Renault',
            mark11: 'Volkswagen',

            model: 'Spark',
            model1: 'Lanos',
            model2: 'Forza',
            model3: 'Palio',
            model4: 'Punto',
            model5: 'Tipo',
            model6: 'Siena',
            model7: 'Fiesta',
            model8: 'Ikon',
            model9: 'Ka',
            model10: 'Accent',
            model11: 'Elantra',
            model12: '323',
            model13: 'Mazda2',
            model14: 'Protege',
            model15: 'Lancer',
            model16: 'Astra',
            model17: 'Corsa',
            model18: '106',
            model19: '107',
            model20: 'Clio',
            model21: 'Symbol',
            model22: 'Golf',

            url: '/pdf/CSC-802.pdf',
        },

        {
            id: 31,
            img: "/images/CSC-802.webp",
            title: 'CSC-802',

            med: '195/55R15',
            med1: '195-55R15',
            med2: '195 55R15',
            med3: '195 55R 15',
            med4: '195 / 55R 15',
            med5: '195-55 R 15',
            med6: '195 55 R 15',
            med7: '195/5515',
            med8: '195-5515',
            med9: '195 5515',
            med10: '195 55 15',
            med11: '195 / 55 15',
            med12: '195-55  15',
            med13: '195 55  15',
            med14: '195/55 15',
            med15: '195-55 15',

            mark: 'Chevrolet',
            mark1: 'Daewoo',
            mark2: 'Dodge',
            mark3: 'Fiat',
            mark4: 'Ford',
            mark5: 'Honda',
            mark6: 'Hyundai',
            mark7: 'Kia',
            mark8: 'Mazda',
            mark9: 'Mitsubishi',
            mark10: 'Nissan',
            mark11: 'Opel',
            mark12: 'Peugeot',
            mark13: 'Renault',
            mark14: 'Toyota',
            mark15: 'Volkswagen',

            model: 'Optra',
            model1: 'Spark EV',
            model2: 'LeMans',
            model3: 'Racer',
            model4: 'Prince',
            model5: 'Neon',
            model6: 'Idea',
            model7: 'Palio',
            model8: 'Punto',
            model9: 'Siena',
            model10: 'Aspire',
            model11: 'Focus',
            model12: 'Fiesta',
            model13: 'Ka',
            model14: 'Accord',
            model15: 'Civic',
            model16: 'Prelude',
            model17: 'Accent',
            model18: 'Elantra LaVita',
            model19: 'Rio',
            model20: '323',
            model21: 'Allegro',
            model22: 'Protege',
            model23: 'Lancer',
            model24: 'Sentra',
            model25: 'Astra',
            model26: '206',
            model27: '207',
            model28: 'Clio',
            model29: 'Kangoo',
            model30: 'Symbol',
            model31: 'Megane',
            model32: 'Corolla',
            model33: 'CrossFox',
            model34: 'Fox',
            model35: 'Gol',
            model36: 'Polo',
            model37: 'SpaceFox',

            url: '/pdf/CSC-802.pdf',
        },

        {
            id: 18,
            img: "/images/CSC-802.webp",
            title: 'CSC-802',

            med: '195/55R16',
            med1: '195-55R16',
            med2: '195 55R16',
            med3: '195 55R 16',
            med4: '195 / 55R 16',
            med5: '195-55 R 16',
            med6: '195 55 R 16',
            med7: '195/5516',
            med8: '195-5516',
            med9: '195 5516',
            med10: '195 55 16',
            med11: '195 / 55 16',
            med12: '195-55  16',
            med13: '195 55  16',
            med14: '195/55 16',
            med15: '195-55 16',


            mark: 'Chevrolet',
            mark1: 'Volkswagen',
            mark2: 'Toyota',
            mark3: 'Fiat',
            mark4: 'Ford',
            mark5: 'Honda',
            mark6: 'Hyundai',
            mark7: 'Subaru',
            mark8: 'Mazda',
            mark9: 'Mitsubishi',
            mark10: 'Peugeot',
            mark11: 'Renault',
            mark12: 'Nissan',

            model: 'Optra',
            model1: 'Palio',
            model2: 'Fiorino',
            model3: 'Siena',
            model4: 'Fiesta',
            model5: 'Fusion',
            model6: 'Accord',
            model7: 'Civic',
            model8: 'Civic Ferio',
            model9: 'Accent',
            model10: 'Elantra',
            model11: 'Capella',
            model12: 'Aspire',
            model13: 'Eclipse',
            model14: 'Lancer',
            model15: '207',
            model16: 'Patner Rapid',
            model17: 'Clio',
            model18: 'Dokker',
            model19: 'Kangoo',
            model20: 'Logan',
            model21: 'Megane',
            model22: 'Scala',
            model23: 'Scenic',
            model24: 'Symbol',
            model25: 'Impreza',
            model26: 'Corolla',
            model27: 'Corolla EX',
            model28: 'Yaris',
            model29: 'Belta',
            model30: 'Prius',
            model31: 'Polo',
            model32: 'Sentra',

            url: '/pdf/CSC-802.pdf',
        },
        {
            id: 19,
            img: "/images/CST-113.webp",
            title: 'CST-113 14PR',
            med: '7.50R16',
            med1: '7.50 R16',
            med2: '7.50 R 16',
            med3: '7,50 R 16',
            med4: '7,50 R16',
            med5: '7.5016',
            med6: '7.50 16',
            med7: '7.50  16',
            med8: '7,50  16',
            med9: '7,50 16',

            url: '/pdf/CST113.pdf',
        },
        {
            id: 20,
            img: "/images/CST127.jpg",
            title: 'CST-127S',

            med: '295/80R22.5',
            med1: '295-80R22.5',
            med2: '295 80R22.5',
            med3: '295 80R 22.5',
            med4: '295 / 80R 22.5',
            med5: '295-80 R 22.5',
            med6: '295 80 R 22.5',
            med7: '295/8022.5',
            med8: '295-8022.5',
            med9: '295 8022.5',
            med10: '295 80 22.5',
            med11: '295 / 80 22.5',
            med12: '295-80  22.5',
            med13: '295 80  22.5',
            med14: '295/80 22.5',
            med15: '295-80 22.5',

            url: '/pdf/CST127S.pdf',
        },

    ]
}

export default data;