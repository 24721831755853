import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_falcon() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Falcón</h2>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">RUEDAS PENINSULA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AVENIDA RAFAEL GONZÁLEZ, ENTRE CALLE MONAGAS Y TALAVERA, LOCAL S/N, SECTOR PUNTO FIJO.<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584246556775" target="_blank">+58 424-6556775</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ruedaspeninsula/" target="_blank">@ruedaspeninsula</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">EL MUNDO DEL CAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE MARIÑO, CON AVENIDA MONAGAS, LOCAL S/N PUNTO FIJO / FALCÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584147927709" target="_blank">+58 414-7927709</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/elmundodelcaucho/" target="_blank">@elmundodelcaucho</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">BARRAKI MOTORS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE MAPARARI, LOCAL #13, SECTOR BOBARE, SANTA DE CORO, FALCÓN<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584146852163" target="_blank">+58 414-6852163</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">TODO RUEDAS CORO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AVENIDA TISO SALAVERRIA CON AVENIDA EL TENIS Y CALLE JABONERIA LOCAL TODO RUEDAS CORO FRENTE AL DEPOSITO DE LA PEPSI <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584146898931" target="_blank">+58 414-6898931</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/todoruedascoro/" target="_blank">@todoruedascoro</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">MULTILLANTAS LA PUERTA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AVENIDA OLLARVIDES, CON CALLE CABURE, LOCAL S/N SECTOR PUERTA MARAVEN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584146728365" target="_blank">+58 414-6728365</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/prollantaspf/" target="_blank">@prollantaspf</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">R.R. RADIALES, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE COMERCIO DE CAJA AGUA, LOCAL S/N PUNTO FIJO<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584246641017" target="_blank">+58 424-6641017</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/radiales2022/" target="_blank">@radiales2022</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">TECNO CAUCHOS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE LIBERTAD, FRENTE A FERREMACO, LOCAL S/N CORO EDO. FALCÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584242815419" target="_blank">+58 424-2815419</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/tecno_caucho/" target="_blank">@tecno_caucho</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion><br></br><br></br>

                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_falcon;