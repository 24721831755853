import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_lara() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Lara</h2>
                            <br></br><br></br>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">SUPER CAUCHOS MERCABAR, S.A.</Accordion.Header>
                                    <Accordion.Body>
                                    <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584125196017" target="_blank">+58 412-5196017</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/gruposupercauchos/" target="_blank">@gruposupercauchos</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2335.7837157999743!2d-69.3913109396491!3d10.074132828646487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e876504996b79a9%3A0x3dc8c613aab389e0!2sGrupo%20Super%20Cauchos!5e0!3m2!1ses!2sve!4v1687533340388!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">RALI CAUCHOS (PRINCIPAL), C.A.</Accordion.Header>
                                    <Accordion.Body>
                                    <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584145144649" target="_blank">+58 414-5144649</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ralicauchos/" target="_blank">@ralicauchos</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d982.0532699542479!2d-69.30317493000348!3d10.081619492848919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDA0JzUzLjMiTiA2OcKwMTgnMDkuOCJX!5e0!3m2!1ses!2sve!4v1687533494339!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">RODDAR MULTISERVICIOS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                    <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584145138206" target="_blank">+58 414-5138206</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchosroddar/" target="_blank">@cauchosroddar</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d982.0683562009532!2d-69.34001692805552!3d10.076667819874185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDA0JzM1LjgiTiA2OcKwMjAnMjIuNiJX!5e0!3m2!1ses!2sve!4v1687533690448!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">RALI CAUCHOS (SUCURSAL), C.A.</Accordion.Header>
                                    <Accordion.Body>
                                    <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584145144649" target="_blank">+58 414-5144649</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ralicauchos/" target="_blank">@ralicauchos</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1388.980136527677!2d-69.39515399269257!3d10.047433943948976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8765f5ba261c91%3A0x63bd3d5b29592201!2sRali%20Cauchos%20C.A%20(oeste)!5e0!3m2!1ses!2sve!4v1687533809347!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>



                            </Accordion>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>

                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_lara;