import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_tachira() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Táchira</h2>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">SUPER CAUCHOS USECHE K9, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584140749648" target="_blank">+58 414-0749648</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/supercauchos_usechek9/" target="_blank">@supercauchos_usechek9</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1976.2881505324701!2d-72.24577360922662!3d7.834586472182795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNTAnMDQuNCJOIDcywrAxNCc0My42Ilc!5e0!3m2!1ses!2sve!4v1687529078766!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS S. J. CHACON, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584267704624" target="_blank">+58 426-7704624</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/sjchaconmultiservicios/" target="_blank">@sjchaconmultiservicios</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2349.911294682989!2d-72.25066049988837!3d7.888376766336402!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNTMnMTcuOSJOIDcywrAxNScwMC41Ilc!5e0!3m2!1ses!2sve!4v1687529242712!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">OMCAR'S 17, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247745404" target="_blank">+58 424-7745404</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/omcar_s17/" target="_blank">@omcar_s17</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1976.8456663835657!2d-72.24690353432315!3d7.716230533623879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNDInNTguMSJOIDcywrAxNCc0Ny43Ilc!5e0!3m2!1ses!2sve!4v1687529620712!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">MECA CAUCHOS, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147506682" target="_blank">+58 414-7506682</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/meca.cauchos/" target="_blank">@meca.cauchos</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1175.314151706562!2d-72.2300492421383!3d7.7611823940347895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNDUnNDAuNCJOIDcywrAxMyc0Ny4yIlc!5e0!3m2!1ses!2sve!4v1687529819918!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">CAUCHOS J.A., C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247766790" target="_blank">+58 424-7766790</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1396.1302400777276!2d-72.24883106989242!3d8.217570695251245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e668d2ee0f0f2c5%3A0x5cffa48bd7981e76!2sCauchos%20J.A!5e0!3m2!1sen!2sve!4v1687529993241!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">MULTIREPUESTOS J.D., C.A</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247204683" target="_blank">+58 424-7204683</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multirepuestosjdfp/" target="_blank">@multirepuestosjdfp</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1661.8890333353008!2d-72.21297431842054!3d7.825708508090745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNDknMzIuNCJOIDcywrAxMic0NC44Ilc!5e0!3m2!1ses!2sve!4v1687530248041!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">INVERSIONES JS 2020, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584126620325" target="_blank">+58 412-6620325</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/inversiones.js2020/" target="_blank">@inversiones.js2020</a> <br /><br />

                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d830.9389366374498!2d-72.22731159878406!3d7.828507466841367!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNDknNDIuNCJOIDcywrAxMyczOC4yIlc!5e0!3m2!1ses!2sve!4v1687530505995!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header class="acordion-header">RUMECA RUEDAS Y NEUMÁTICOS, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584126453332" target="_blank">+58 412-6453332</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1973.9026731897277!2d-72.09438528191544!3d8.322132298546428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTknMTkuMSJOIDcywrAwNSczOC4yIlc!5e0!3m2!1ses!2sve!4v1687530671151!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="8">
                                    <Accordion.Header class="acordion-header">CAUCHOS WILLIAM DEL HUMOGRIA, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147408258" target="_blank">+58 414-7408258</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2792.792644687861!2d-71.99184032314302!3d8.14160845028601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMDgnMjguMyJOIDcxwrA1OScyOS4xIlc!5e0!3m2!1ses!2sve!4v1687530809777!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion><br></br><br></br>

                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_tachira;