import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
    return (
        <>
            <div className='footer-container'>
                <br />

                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-4'></div>
                        <div className='col-sm-4'>
                            <div className='social-icons'>
                                <a className='alink'
                                    target='_blank'
                                    href='https://www.instagram.com/chengshan.ve/'
                                    aria-label='Instagram'>
                                    <FontAwesomeIcon className='social-icons' icon={faInstagram} />
                                </a>
                                <a className='alink'
                                    target='_blank'
                                    href='https://twitter.com/PrinxChengshan'
                                    aria-label='Twitter'>
                                    <FontAwesomeIcon className='social-icons' icon={faTwitter} />
                                </a>
                                <a className='alink'
                                    target='_blank'
                                    href='https://www.facebook.com/chengshan.ve/'
                                    aria-label='Facebook'>
                                    <FontAwesomeIcon className='social-icons' icon={faFacebook} />
                                </a>
                                <a className='alink'
                                    target='_blank'
                                    href='https://www.youtube.com/@prinxchengshan4115'
                                    aria-label='Youtube'>
                                    <FontAwesomeIcon className='social-icons' icon={faYoutube} />
                                </a>
                            </div>
                        </div>
                        <div className='col-sm-4'></div>
                    </div>
                </div>
                <br />

                <center>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-2'></div>
                            <div className='col-lg-2'><a className='alink' href='/'>Inicio</a></div>
                            <div className='col-lg-2'><a className='alink' href='/nosotros'>Nosotros</a></div>
                            <div className='col-lg-2'><a className='alink' href='/productos'>Productos</a></div>
                            <div className='col-lg-2'><a className='alink' href='/comercios'>Comercios</a></div>
                            <div className='col-lg-2'></div>
                        </div>
                    </div>
                </center>
                <br />
                <br />



                <div className='row'>
                    <center>
                        <div className='col-lg-4'></div>
                        <div className='col-lg-4'>
                            <a href='/' className='social-logo'>
                                <img src='images/logo-chengshan.png' width={200} />
                            </a>
                        </div>
                        <div className='col-lg-4'></div>
                    </center>
                </div>

                <div className='row'>
                    <center>
                        <div className='col-lg-4'></div>
                        <div className='col-lg-4'>
                            <a href='https://www.cauchoslamundial.com/' target='_blank' className='social-logo'>
                                <img src='images/clm.png' width={200} />
                            </a>
                        </div>
                        <div className='col-lg-4'></div>
                    </center>
                </div>

                <br /><center>
                    <div className='container'>
                        <span className='website-rights'>© <a className='chengshan-ven' href='/'>CHENGSHAN Venezuela.</a> Todos los derechos reservados. Diseñado por Grupo La Mundial C.A.</span>
                    </div>
                </center>
            </div>
        </>
    );
}

export default Footer;
