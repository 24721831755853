import React from "react";
import "../pages/Informacion.css";
import { Container } from "react-bootstrap";

function Informacion() {

    return (
        <>
            <body className="container-body">
                <Container><br /><br /><br /><br /><br />
                    <font className="slogan">NEUMÁTICOS FUERTES PARA UN DESEMPEÑO MÁS LARGO</font><br /><br />
                    <p className="description">NEUMÁTICOS DE CHENGSHAN
                        es una marca de gama semi-alta propiedad de Prinx Chengshan ( Shandong ) Tire Co. Ltd , que es un fabricante de neumáticos de pasajeros, neumáticos comerciales, neumáticos OTR y neumáticos agrícolas.
                        Como pionero en la industria china de neumáticos, Chengshan ha sido galardonado con el Premio Nacional de Progreso en Ciencia y Tecnología varias veces.
                        En 2020, Chengshan fue incluido en el ‘ Las 500 marcas más valiosas de China ‘ lanzado por World Brand Lab , y seleccionado como ‘ Las 10 principales marcas de neumáticos de China ’.
                    </p><br /><br />


                    <span><font><font className="caracteristics">PERSISTENCIA</font></font></span>   <font className="caracteristics">VALIENTE</font>   <font className="caracteristics">ENDURO</font><br/><br/>

                    <p className="history">A partir de la hora de la fundación en 1976, Chengshan adherido a un camino trabajador con los pies en la tierra.
                        Chengshan tiene como objetivo desarrollar productos más duraderos con mayor kilometraje y mayor calidad para los usuarios.
                        El espíritu persistente similar a la posición Chengshan Tau El faro junto al mar ha estado liderando la dirección y guiando la mente de todos durante dos siglos.
                    </p>

                    <p className="history">Seguiremos fortaleciendo nuestra marca y poder de producto.
                        Dedíquenos a proporcionar a cada conductor productos más adecuados y servicios conmovedores.</p><br/><br/>
                </Container>
            </body>
        </>
    );
}

export default Informacion;