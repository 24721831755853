import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_trujillo() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Trujillo</h2>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">CAUCHOS MAYA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> Calle 8 Con Calle Maya , Plata 1, Municipio Valera Estado Trujillo. <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchosmaya1/" target="_blank">@cauchosmaya1</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">INVERSIONES DAYTONA GROUP, F.P.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> Carrera 4ta vía bailadores , sector el llano 200 metros arriba del hospital San Jose. <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/rmendez9744/" target="_blank">@rmendez9744</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">RASHYD & RASHEL TIRES, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        DIRECCIÓN: SECTOR SABANETA, DIAGONAL AL MONUMENTO LA CHINITA DE LA C1.<br/>
                                        TELÉFONO: +58 412-2618392.<br></br><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2772.8260344166465!2d-71.64203876495948!3d10.628478416304889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8999beca66dc79%3A0x36e5800889123875!2sRuedas%20La%20Mundial%20Sabaneta!5e0!3m2!1sen!2sve!4v1685476457134!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">MULTISERVICIO J.A., C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multiservicioj_a/" target="_blank">@multiservicioj_a</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1658.615145632564!2d-71.6599981577897!3d8.608107153393593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMzYnMjguNSJOIDcxwrAzOSczNC45Ilc!5e0!3m2!1ses!2sve!4v1687528157681!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </Accordion><br></br><br></br>
                        </div>
                    </center>
                </div >
            </body >
            <Footer />
        </>
    );
}

export default Comercio_trujillo;