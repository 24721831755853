import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_miranda() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Miranda</h2>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">INVERSIONES CUICAUCHO J.A.S., C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584241954539" target="_blank">+58 424-1954539</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cuicaucho.oficial/" target="_blank">@cuicaucho.oficial</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1387.017708107271!2d-66.82733954936018!3d10.494477362988693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a5972ed5415b1%3A0xb492d21c305e5f83!2sCuicaucho%20II!5e0!3m2!1sen!2sve!4v1687546557860!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                            </Accordion>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>

                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_miranda;