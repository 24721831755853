import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_bolivar() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Bolívar</h2>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS Y CAUCHERA LOS GOCHOS, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584148817822" target="_blank">+58 414-8817822</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/caucheralosgochos/" target="_blank">@caucheralosgochos</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1973.677531736042!2d-62.66584046637346!3d8.366689537521285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf177c81d4a03%3A0xf0973e81939a6ae0!2sMULTISERVICIOS%20Y%20CAUCHERA%20LOS%20GOCHOS%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687547180729!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS UNARE, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584148855523" target="_blank">+58 414-8855523</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/servicauchosunare_/" target="_blank">@servicauchosunare_</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1660.0405806547676!2d-62.76968311640971!3d8.276487978453968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTYnMzUuNiJOIDYywrA0NicxMC40Ilc!5e0!3m2!1sen!2sve!4v1687547285472!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS LA UNIDAD, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584249534602" target="_blank">+58 424-9534602</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchos_launidad/" target="_blank">@cauchos_launidad</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2347.088991980071!2d-62.65209428988334!3d8.370401936417824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMjInMTQuMCJOIDYywrAzOScwNi40Ilc!5e0!3m2!1sen!2sve!4v1687547406120!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">CAUCHOS J.D., C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584148755144" target="_blank">+58 414-8755144</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grupojdpzo/" target="_blank">@grupojdpzo</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1173.8210425310167!2d-62.766708216728624!3d8.278135072769283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf96edef6b0bd%3A0xdf4add8b60c9f9d6!2sCauchos%20JD%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687547534042!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">SUPER KAUCHOS, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584249700126" target="_blank">+58 424-9700126</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/superkauchos/" target="_blank">@superkauchos</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1660.686135471869!2d-63.563502044028205!3d8.121882281047224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dce873d20df19b9%3A0x7d6dfb781f34225c!2sSuper%20Kauchos!5e0!3m2!1sen!2sve!4v1687547651169!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS EL POLACO, C.A</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584249223671" target="_blank">+58 424-9223671</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multicauchoselpolaco/" target="_blank">@multicauchoselpolaco</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1661.157046659288!2d-62.393571799844985!3d8.00723252646281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dc9677c0e998b69%3A0x45a25ce21384529c!2sMulticauchos%20El%20Polaco!5e0!3m2!1sen!2sve!4v1687547775044!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">SUPER CAUCHOS LA VICTORIA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584148810042" target="_blank">+58 414-8810042</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/lavictoria.ca/" target="_blank">@lavictoria.ca</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1396.8504586596002!2d-62.391561687349196!3d8.01030733062893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dc967e8d8f0c327%3A0xca76be0322a3863d!2sServicachos%20La%20Victoria!5e0!3m2!1sen!2sve!4v1687547876404!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS ALEX, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584249531453" target="_blank">+58 424-9531453</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/servicauchos_alex/" target="_blank">@servicauchos_alex</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1659.6558105476474!2d-62.64747897080982!3d8.367288257885255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf1ecb2a5fb81%3A0xf241fbe69b4d5f55!2sservicauchosalex!5e0!3m2!1sen!2sve!4v1687548016250!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/*  */}

                                <Accordion.Item eventKey="8">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS EL GOCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>

                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584148817822" target="_blank">+58 414-8817822</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multicauchoselgocho/" target="_blank">@multicauchoselgocho</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1395.6345527947644!2d-62.633639840550195!3d8.357253036337728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMjEnMjUuOCJOIDYywrAzOCcwMC4wIlc!5e0!3m2!1sen!2sve!4v1687548140920!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion><br></br><br></br>

                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_bolivar;