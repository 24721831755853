import React from "react";
import '../../App.css';
import MainSection from '../MainSection';
import Footer from "../Footer";
import Informacion from '../pages/Informacion';

function Inicio() {

    return (
        <>
        <MainSection />
        <Informacion />
        <Footer />
        </>
    );
}

export default Inicio;