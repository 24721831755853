import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_aragua() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Aragua</h2>
                            <br></br><br></br>
                            <br></br><br></br>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">GRUPO TURAGUA PRIX, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                    <FontAwesomeIcon icon={faLocationArrow} /> AVENIDA INTERCOMUNAL <br/>
                                    <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel: +584243055424" target="_blank">+58 424-3055424</a> <br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grupoturaguaprix/" target="_blank">@grupoturaguaprix</a> <br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                            </Accordion><br></br><br></br>
                        </div>
                    </center>
                </div >
            </body >
            <Footer />
        </>
    );
}

export default Comercio_aragua;